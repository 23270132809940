<template>
  <div class="objects-page">
    <div>

      <b-modal
        id="modal-add-object"
        shadow
        bg-variant="white"
        backdrop
        hide-footer
        centered
        size="sm"
        :title="$t('IntegrationPage.CreateObject')"
      >
        <add-object
          @show-message="closeSideBarAddObject"
          @refetch-data="getObjects"
          :listagemContexto="rows"
          @atualizaJson="(value) => openModalDatails(value)"
          @atualizaPreviewData="(value) => openModalPreviewData(value)"
        />
      </b-modal>

      <b-modal
        id="modal-edit-object"
        shadow
        bg-variant="white"
        backdrop
        hide-footer
        centered
        :title="$t('IntegrationPage.EditObject')"
      >
        <edit-object
          :objectId="objectId"
          @show-message="closeSideBarEditObject"
          @refetch-data="getObjects"
          :listagemContexto="rows"
          @atualizaJson="(value) => openModalDatails(value)"
          @atualizaPreviewData="(value) => openModalPreviewData(value)"
        />
      </b-modal>

      <b-modal id="modal-json-details" size="lg" scrollable title="Json">
        <b-col cols="12">
          <pre class="preview-json">{{ JsonModal | pretty }}</pre>
        </b-col>
        <template #modal-footer>
          <b-button @click="backModalObject" class="btn-close-json">
            {{ $t('IntegrationPage.CloseJson') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-preview-data"
        size="lg"
        scrollable
        :title="$t('IntegrationPage.PreviewData')"
      >
        <b-col cols="12" class="mt-2">
          <div class="modal-titles">
            <span>{{ $t('IntegrationPage.object') }}:</span>
          </div>

          <pre class="preview-json">{{ PreviewData | pretty }}</pre>
        </b-col>

        <template #modal-footer>
          <b-button @click="backModalObject" class="btn-close-json">
            {{ $t('IntegrationPage.ClosePreview') }}
          </b-button>
        </template>
      </b-modal>

      <b-modal
        id="modal-edit-schemas"
        size="lg"
        :title="$t('IntegrationPage.EditSchemas')"
        centered
        hide-footer
      >
        <edit-schema
          :object="selectedObject"
          @validade-query="(value) => validateQuery(value)"
          @close-edit-modal="$bvModal.hide('modal-edit-schemas')"
        />
      </b-modal>

      <b-modal
        id="modal-preview-schemas"
        size="lg"
        :title="$t('IntegrationPage.PreviewData')"
        centered
      >
        <b-table
          :items="schemasPreviewData"
          :fields="previewDataFields"
          id="table-preview-schemas"
          responsive
          v-if="validQuery"
        >
        </b-table>
        <div v-else class="p-1">
          <p class="sub-title">{{ $t('Error') }}</p>
          <span class="error-desc">
            {{ errorQuery }}
          </span>
        </div>

        <template #modal-footer>
          <b-button @click="backModalSchema" class="btn-close-json">
            {{ $t('IntegrationPage.ClosePreview') }}
          </b-button>
        </template>
      </b-modal>

      <b-card>
        <div>
          <!-- search input -->
          <div class="d-flex align-items-center justify-content-between card-header-object p-0">
            <div class="container-item-left p-0">
              <span>{{ $t('IntegrationPage.DataSet') }}</span>
            </div>

            <div class="container-item-right" v-if="type.id > 1">
              <div
                class="col-12 d-flex align-items-center justify-content-end container-buttons p-0"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="getObjects()"
                  class="btn-update-all d-flex justify-content-center reload"
                >
                  <ReloadIcon />
                  <span class="align-middle">{{ $t('IntegrationPage.Refresh') }}</span>
                </b-button>
                <b-button
                  v-if="testing"
                  style="margin-right: 5px"
                  variant="primary"
                  disabled
                  class="btn-card-header btn-sync-all"
                >
                  <b-spinner small style="margin-right: 5px" />
                  {{ $t('IntegrationPage.Testing') }}...
                </b-button>

                <b-button
                  :disabled="type.id == 1 || rows.length == 0 ? true : false"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  @click="updateStatus(0)"
                  v-if="!testing"
                  class="btn-card-header btn-sync-all"
                >
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span class="align-middle"> {{ this.$t('IntegrationPage.SyncAll') }}</span>
                </b-button>

                <b-button
                  :disabled="type.id == 1 ? true : false"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.modal-add-object
                  class="btn-card-header btn-new-object"
                >
                  <span class="align-middle">{{ $t('IntegrationPage.NewDataSet') }}</span>
                </b-button>
              </div>
            </div>
          </div>
          <!-- table -->
          <table-default
            :rows="rows"
            :fields="fields"
            :stickyHeader="false"
            :striped="true"
            :sizeSearch="12"
            :class="rows.length > 0 ? 'table-void' : ''"
            id="table-data-source-object"
            @update-displayed-rows="(v) => (displayedRows = v)"
          >
            <template v-slot:cell(status)="data">
              <b-spinner
                label="Loading..."
                variant="primary"
                v-if="data.item.status == 'Fetching...'"
              />
              <b-badge
                v-else
                :variant="
                  data.item.status == 'Fetching...'
                    ? 'warning'
                    : data.item.status == 'Synchronized'
                    ? 'success'
                    : 'danger'
                "
              >
                {{ $t(`IntegrationPage.${data.item.status}`) }}
              </b-badge>
            </template>

            <template v-slot:cell(action)="data">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                  class="col-action"
                  id="d-action-data-source-object"
                  v-if="data.item.type_id > 1"
                  :boundary="displayedRows > 3 ? 'scrollParent' : 'window'"
                  dropright
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25 icon-action"
                    />
                  </template>

                  <b-dropdown-item
                    @click="updateStatus(data.item.id)"
                    :disabled="
                      data.item.type_id == 1 || data.item.status === 'Fetching...' ? true : false
                    "
                  >
                    <ImgTestIcon />
                    <span>{{ $t('IntegrationPage.SyncMetadata') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="setObjectId(data.item.id, data.item.type)"
                    :disabled="
                      data.item.type_id == 1 || data.item.status === 'Fetching...' ? true : false
                    "
                  >
                    <ImgEditIcon />
                    <span>{{ $t('IntegrationPage.Edit') }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    @click="deleteObject(data.item.id)"
                    :disabled="data.item.type_id == 1 ? true : false"
                  >
                    <ImgDeleteIcon />
                    <span>{{ $t('IntegrationPage.delete') }}</span>
                  </b-dropdown-item>
                </b-dropdown>

                <div
                  @click="openModalEdit(data.item)"
                  class="btn-edit"
                  :id="`tooltip-edit-schema-${data.index}`"
                  v-else
                >
                  <EditIcon />
                </div>

                <b-tooltip
                  triggers="hover blur"
                  :target="`tooltip-edit-schema-${data.index}`"
                  positioning="top"
                  noninteractive
                  placement="top"
                  boundary-padding="0"
                  custom-class="tooltip-schema"
                >
                  <div class="info-tooltip-schema">
                    {{ $t('IntegrationPage.EditSchemas') }}
                  </div>
                </b-tooltip>
              </span>
            </template>
          </table-default>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
  import {
    BModal,
    BCol,
    BRow,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BAlert,
    BDropdown,
    BDropdownItem,
    BCard,
    BSidebar,
    VBToggle,
    BButton,
    BSpinner,
    BTooltip,
    BTable,
    BCardText
  } from 'bootstrap-vue';
  import { VueGoodTable } from 'vue-good-table';
  import store from '@/store/index';
  import Ripple from 'vue-ripple-directive';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import ImgDeleteIcon from '@/assets/images/pages/delete.svg';
  import ImgEditIcon from '@/assets/images/pages/edit2.svg';
  import ImgTestIcon from '@/assets/images/pages/check-circle.svg';
  import TableDefault from '@/@core/components/table-default/TableDefault.vue';
  import ReloadIcon from '@/assets/images/pages/Reload.svg';
  import AddObject from './AddObject.vue';
  import EditObject from './EditObject.vue';
  import EditIcon from '@/assets/images/icons/edit.svg';
  import EditSchema from './EditSchema.vue';
  import { mapMutations } from 'vuex';

  export default {
    components: {
      BRow,
      BCol,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BAlert,
      BDropdown,
      BDropdownItem,
      BCard,
      ToastificationContent,
      BSidebar,
      BButton,
      BSpinner,
      BModal,
      BTooltip,
      BCardText,
      ImgDeleteIcon,
      ImgEditIcon,
      ImgTestIcon,
      TableDefault,
      ReloadIcon,
      AddObject,
      EditObject,
      EditIcon,
      EditSchema,
      BTable
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    filters: {
      pretty: function (value) {
        return JSON.stringify(value, null, 2);
      }
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        pageLength: 10,
        dir: false,
        objectId: '',
        JsonModal: '',
        PreviewData: '{}',
        fields: [
          {
            label: 'IntegrationPage.Actions',
            key: 'action',
            sortable: false
          },
          {
            label: 'ID',
            key: 'id',
            sortable: true
          },
          {
            label: 'STATUS',
            key: 'status',
            sortable: true
          },
          {
            label: 'IntegrationPage.Name',
            key: 'connection',
            sortable: true,
            class: 'col-name-type'
          },
          {
            label: 'IntegrationPage.DataSet',
            key: 'object',
            sortable: true,
            class: 'col-name-type text-upper'
          }
        ],
        rows: [],
        searchTerm: '',
        refreshing: false,
        testing: false,
        type: '',
        schemasPreviewData: [],
        previewDataFields: [],
        validQuery: false,
        selectedObject: null,
        intervalFunction: 0,
        errorQuery: null,
        displayedRows: 0
      };
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      backModalObject() {
        this.$bvModal.hide('modal-json-details');
        this.$bvModal.hide('modal-preview-data');
      },
      backModalSchema() {
        this.$bvModal.show('modal-edit-schemas');
        this.$bvModal.hide('modal-preview-schemas');
      },
      openModalDatails(json) {
        this.JsonModal = JSON.parse(json);
        this.$bvModal.show('modal-json-details');
      },
      openModalPreviewData(preview) {
        this.PreviewData = preview;
        this.$bvModal.show('modal-preview-data');
      },
      openModalEdit(item) {
        this.selectedObject = item;
        this.$bvModal.show('modal-edit-schemas');
      },
      atualizaDados() {
        const timeRefreshSeconds = 15000;

        this.intervalFunction = setInterval(() => this.getObjects(), timeRefreshSeconds);
      },
      validateQuery(object) {
        if (object.valid) {
          this.previewDataFields = [];
          let fields = Object.getOwnPropertyNames(object.data[0]);

          fields.forEach((field) => {
            this.previewDataFields.push({
              key: field,
              sortable: true
            });
          });

          this.schemasPreviewData = object.data;
          this.validQuery = true;
        } else {
          this.errorQuery = object.data;
          this.validQuery = false;
        }

        this.$bvModal.show('modal-preview-schemas');
      },
      getConnectionType() {
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/connection/type?type_id=${this.$router.currentRoute.params.type}`
          )
          .then((res) => (this.type = res.data));
      },
      setObjectId(id, type) {
        this.objectId = id;
        this.$bvModal.show('modal-edit-object');
      },
      getObjects() {
        <b-spinner label="Loading..." />;
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        this.$http
          .get(
            `/api/${this.$router.currentRoute.params.prefix}/integration/object?site_guid=${this.currentSite.guid}&connection_id=${this.$router.currentRoute.params.connection_id}`
          )
          .then((res) => {
            this.rows = res.data;
            this.refreshing = false;
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      refreshObjects() {
        this.refreshing = true;
        this.getObjects();
      },
      updateStatus(id) {
        this.testing = true;
        this.$http
          .put(
            `/api/${this.$router.currentRoute.params.prefix}/integration/object/${id}?site_guid=${this.currentSite.guid}`,
            { connection_id: this.$router.currentRoute.params.connection_id, object_status_id: 2 }
          )
          .then((response) => {
            this.getObjects();
            this.testing = false;
          })
          .catch((error) => {
            this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
            this.testing = false;
          });
      },
      deleteObject(id) {
        this.$swal({
          title: this.$t('IntegrationPage.attention'),
          text: this.$t('IntegrationPage.deleteObject'),
          showCancelButton: true,
          showCloseButton: true,
          cancelButtonText: this.$t('IntegrationPage.cancel'),
          confirmButtonText: this.$t('IntegrationPage.delete'),
          customClass: {
            container: 'swal-default',
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then((result) => {
          if (result.value) {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            this.$http
              .delete(
                `/api/${this.$router.currentRoute.params.prefix}/integration/object/${id}?site_guid=${this.currentSite.guid}`
              )
              .then(() => {
                this.getObjects();
                this.showToast(
                  this.$t('IntegrationPage.success'),
                  'SmileIcon',
                  this.$t('IntegrationPage.deleteObjectSuccess'),
                  'success'
                );
              })
              .catch((error) => {
                this.UPDATE_FLAG_SHOW_OVERLAY(false);
                this.showToast('Error!', 'FrownIcon', error.response.data.message, 'danger');
              });
          }
        });
      },
      closeSideBarAddObject() {
        this.$bvModal.hide('modal-add-object');
      },
      closeSideBarEditObject() {
        this.$bvModal.hide('modal-edit-object');
      },
      statusVariant(status) {
        let variant;
        switch (status) {
          case 'Fetching...':
            variant = 'warning';
            break;
          case 'Synchronized':
            variant = 'success';
            break;
          case 'Not found':
            variant = 'danger';
            break;
        }
        return variant;
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      }
    },
    computed: {
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true;
          return this.dir;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false;
        return this.dir;
      }
    },
    created() {
      this.getObjects();
      this.getConnectionType();
      this.atualizaDados();
    },
    destroyed() {
      clearInterval(this.intervalFunction);
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-sweetalert.scss';

  .objects-page {
    overflow-x: hidden;
    min-height: calc(100vh - 7rem);

    .tooltip-schema {
      z-index: 1039 !important;
      //não adicione padding aqui e só estilize as classes especificas se possível
      margin: 0;
      .tooltip-inner {
        padding: 0;
        max-width: 100%;
        min-width: 1%;
        border-radius: 6px;
        overflow: hidden;
        .info-tooltip-schema {
          padding: 8px;
          max-width: 100%;
          text-align: left;
          font-weight: 400;
          font-size: 14px;
        }
      }

      .arrow {
        &::before {
          border-top-color: #323232;
          border-bottom-color: #323232;
        }
      }
    }

    .col-action {
      .btn {
        padding: 0px;
      }

      .dropdown-menu {
        li {
          .dropdown-item {
            svg {
              margin-right: 9px;
            }
          }

          &:hover {
            background-color: #ffede2 !important;

            span {
              color: #974900 !important;
            }
          }

          &:focus {
            background-color: #ffdbc4 !important;
          }
        }
      }
    }

    .text-upper {
      text-transform: uppercase !important;
    }

    .overlay-mensage {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-direction: column;
    }

    .swal2-close {
      color: #4c4541 !important;
      font-size: 2em;
    }

    .preview-json {
      margin-top: 10px;
      background-color: white !important;
      color: #998f8a;
      font-size: 14px !important;
      font-weight: 600 !important;
    }

    .modal-titles {
      display: flex;
      flex-direction: column;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #4c4541;
    }

    #modal-preview-data,
    #modal-json-details {
      .modal-footer {
        display: flex;
      }
    }

    #table-data-source-object {
      .badge {
        &.badge-success {
          color: #4caf50 !important;
          background: #e8f5e9 !important;
          border-radius: 4px;
        }

        &.badge-danger {
          color: #f44336 !important;
          background: #ffebee !important;
          border-radius: 4px;
        }

        &.badge-warning {
          color: #f4811f !important;
          border-radius: 4px;
          background-color: rgb(244, 129, 31, 0.2);
        }
      }
    }

    .error-desc {
      font-size: 14px;
      line-height: 20px;
      color: #998f8a;
    }

    .btn-close-json {
      font-weight: 600;
      font-size: 17px;
      background-color: #974900 !important;
      border: 1px solid #974900 !important;
      height: 46px;

      &:active {
        background-color: #974900 !important;
      }

      &:focus {
        background-color: #974900 !important;
      }
    }
  }

  #modal-add-object,
  #modal-edit-object,
  #modal-json-details,
  #modal-preview-data,
  #modal-edit-schemas,
  #modal-preview-schemas {
    .modal-dialog {
      width: 95%;
      max-width: none;
    }

    .modal-content {
      width: 100%;
      max-width: none;
    }

    .modal-body {
      padding: 0;

      .modal-bottom {
        padding-bottom: 0 !important;
      }

      .sub-title {
        font-weight: 600;
        font-size: 16px;
        color: #4c4541;
      }
    }

    .modal-content {
      .modal-header {
        padding: 20px 16px;

        .modal-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #4c4541;
        }

        .close {
          transform: none !important;
          transition: none !important;
          top: -16px;
          left: 5px;
        }
      }
    }
  }

  .icon-action {
    color: #974900 !important;
  }

  .btn-new-object {
    background: #974900 !important;
    color: #fff;
  }

  .btn-new-object:focus {
    background: #974900 !important;
  }

  .btn-sync-all {
    width: 140px;
    background: #fff !important;
    color: #974900 !important;
    padding: 0px !important;
  }

  #table-data-source-object {
    td {
      padding: 14.5px 10px !important;
      height: 38px !important;
      letter-spacing: 0;
      border: 1px solid #e0e0e0 !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
    }

    th {
      padding: 11px 9px !important;
    }

    thead th {
      padding: 10.5px 9px !important;
    }

    td:nth-child(1) {
      span {
        display: flex;
        flex-direction: row;
        justify-content: center !important;
        align-items: center;
      }
    }

    th:nth-child(1) {
      padding: 10.5px 7.65px !important;
    }

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 57px !important;
    }

    th:nth-child(2),
    td:nth-child(2) {
      min-width: 52px !important;
      max-width: 52px !important;
    }

    th:nth-child(3),
    td:nth-child(3) {
      min-width: 132px !important;
      max-width: 132px !important;
    }

    th:nth-child(4),
    td:nth-child(4) {
      min-width: 462.5px;
      max-width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
      width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
    }

    th:nth-child(5),
    td:nth-child(5) {
      min-width: 462.5px;
      max-width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
      width: calc(50vw - ((52px + 57px + 132px) / 2) - (2 * 16.8px) + 7px) !important;
    }

    #d-action-data-source-object {
      button {
        width: 32px;
        height: 28px;
        padding: 0;
        svg circle {
          color: #974900 !important;
        }
      }

      .dropdown-menu.show {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
      }

      .dropdown-item {
        padding: 12px 21px 12px 21px !important;
        display: flex;

        &:hover {
          background-color: #fbeee8;
          svg path {
            fill: #974900 !important;
          }
          span {
            color: #974900 !important;
          }
        }
        &.disabled {
          svg {
            path {
              fill: #cfc4be !important;
            }
          }
          .text-action-pipeline {
            color: #cfc4be !important;
          }
        }
        span {
          margin-left: 9px;
        }
      }
      button:hover {
        background-color: #ffdbc4 !important;
      }
      svg {
        margin: 0 !important;
      }
    }
  }

  #table-preview-schemas {
    thead th {
      width: 1%;
      text-transform: none;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #4c4541;
      outline: none;
      border-top: 1px solid #ebe9f1;
      background-color: #faf6f2 !important;
      border-bottom: 0;
      padding: 0.72rem 1rem !important;
      .dark-layout & {
        background-color: #283046 !important;
        outline-color: #3b4253 !important;
      }

      &.col-name-type {
        width: 40%;
      }
    }

    td {
      padding: 19px 10px !important;
      height: 38px !important;
      letter-spacing: 0;
      border: 1px solid #e0e0e0 !important;
      border-bottom: none !important;
      border-left: none !important;
      border-right: none !important;
    }

    tbody tr {
      td {
        width: 1%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #4c4541;
        border: 1px solid transparent !important;
        padding: 0.72rem 1rem !important;
      }

      .custom-switch {
        align-items: center;

        .custom-control-input:checked ~ .custom-control-label::before {
          background-color: #974900 !important;
        }

        .custom-control-input:checked ~ .custom-control-label::after {
          left: -7px !important;
        }

        .custom-control-label::before {
          width: 22px;
          height: 12px;
        }

        .custom-control-label::after {
          top: 1.6px;
          left: 2px;
          width: 8px;
          height: 8px;
        }
      }
    }

    tbody {
      tr:nth-child(2n + 1) {
        background-color: #ffff;
      }

      tr:nth-child(2n) {
        background-color: #fafafa;
      }
    }
  }

  .btn-update-all {
    padding: 7px 11px;
    display: flex;
    align-items: center;
    background-color: #ffffff !important;
    color: #974900 !important;
    border-radius: 6px;
    border: 1px solid #974900 !important;
    font-size: 12px;
    font-weight: 600;

    &:hover {
      background-color: #ffede1 !important;
      box-shadow: none !important;
    }

    &.reload {
      svg {
        margin-right: 7px;
      }
    }

    &:focus {
      background-color: transparent !important;
    }
  }

  .btn-edit {
    width: 32px;
    height: 28px;
    border-radius: 5px !important;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    align-items: center;
    border: none !important;
    background-color: transparent !important;
  }

  .btn-edit:hover {
    cursor: pointer;
    box-shadow: none !important;
    background-color: #ffdbc4 !important;
  }

  .btn-edit:focus {
    background-color: #ffdbc4 !important;
  }

  .btn-edit:active {
    background-color: #ffdbc4 !important;
  }

  @media (max-width: 768px) {
    .tooltip-schema {
      display: none !important;
    }
  }

  @media (min-width: 768px) {
    #modal-add-object,
    #modal-edit-object,
    #modal-json-details,
    #modal-preview-data,
    #modal-edit-schemas,
    #modal-preview-schemas {
      .modal-dialog {
        width: 746px !important;
        max-width: 746px !important;
      }

      .modal-content {
        width: 746px !important;
        max-width: 746px !important;
      }
    }
  }

  @media (max-width: 715px) {
    .card-header-object {
      flex-direction: column;
      align-items: flex-start !important;
      .container-item-left {
        margin-bottom: 10px;
      }
      .container-item-right {
        width: 100%;

        .container-buttons {
          flex-direction: column;
          width: 100%;
          gap: 8px !important;
        }
      }
    }

    .btn-sync-all {
      width: 100%;
    }

    .btn-new-object {
      width: 100%;
    }

    .btn-update-all.reload {
      width: 100%;
    }

    #modal-preview-schemas {
      .modal-footer {
        justify-content: center;
      }
    }

    #table-data-source-object {
      th:nth-child(4),
      td:nth-child(4),
      th:nth-child(5),
      td:nth-child(5) {
        min-width: 352px !important;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .card-header-object {
    margin-bottom: 15px;

    .container-item-left {
      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #4c4541;
      }
    }

    .container-item-right {
      .container-buttons {
        gap: 16px;
      }

      .btn-card-header {
        height: 28px !important;

        font-weight: 600;
        font-size: 12px;
        line-height: 20px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        border: 1px solid #974900 !important;
      }
    }
  }
</style>
